import moment from 'moment';
import _ from 'lodash';
import dayjs from 'dayjs';
import { toPng, toSvg } from 'html-to-image';

import colorPalette from '../assets/scss/_colors.module.scss';
import { LOW_MED_HIGH } from '../sharedComponents/common/charts/modules/contants';

export const formatConsumptionValue = (value, fixed) =>
    value?.toLocaleString(undefined, { maximumFractionDigits: fixed });

export const convertToUserLocalTime = (UTCtime) => {
    // Parse the UTC time string using Moment.js
    const utcMoment = moment.utc(UTCtime);
    // Convert to the user's local timezone
    const localMoment = utcMoment.local();
    // Format the local time in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ)
    const isoFormat = localMoment.format();
    return isoFormat;
};

export const getTimeWithDefault = (type, time) => {
    if (type === 'startTime') {
        if (time) {
            const defaultTime = time ? time.split(':') : [0, 0];
            const [hour, minute] = defaultTime;

            return dayjs()
                .startOf('day')
                .set('hour', +hour)
                .set('minute', +minute);
        } else {
            return dayjs().startOf('day');
        }
    }
    if (type === 'endTime') {
        if (time) {
            const defaultTime = time ? time.split(':') : [0, 0];
            const [hour, minute] = defaultTime;

            return dayjs()
                .startOf('day')
                .set('hour', +hour)
                .set('minute', +minute);
        } else {
            return dayjs().endOf('day');
        }
    }
};

export const prepareTimeAndDateFormat = (dateFormat, timeFormat) => {
    let format = '';
    if (dateFormat == 'DD-MM-YYYY') {
        if (timeFormat == '12h') {
            format = `DD/MM/YYYY hh:mm A `;
        } else if (timeFormat == '24h') {
            format = `DD/MM/YYYY HH:mm:ss`;
        }
    } else if (dateFormat == 'MM-DD-YYYY') {
        if (timeFormat == '12h') {
            format = `MM/DD/YYYY hh:mm A `;
        } else if (timeFormat == '24h') {
            format = `MM/DD/YYYY HH:mm:ss`;
        }
    }
    return format;
};

export const handleDateFormat = (customDate, dateType) => {
    if (dateType === 'startDate' && customDate === null) {
        let startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        return convertToUserLocalTime(startDate);
    }

    if (dateType === 'endDate' && customDate === null) {
        let endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        return convertToUserLocalTime(endDate);
    }

    let dt = new Date(customDate).toISOString();
    return dt;
};

export const xaxisFilters = (daysCount, timezone) => {
    // Up to and including 1 day
    if (daysCount === 1) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('HH:00')}`;
                },
                offsetX: 0,
                offsetY: 0,
            },
            tickAmount: 8,
            tickPlacement: 'between',
        };
        return xaxisObj;
    }

    // Up to and including 3 days
    else if (daysCount >= 2 && daysCount <= 3) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD HH:00')}`;
                },
                offsetX: 0,
                offsetY: 0,
            },
            tickAmount: daysCount * 4,
        };
        return xaxisObj;
    }

    // Up to and including 7 days
    else if (daysCount >= 4 && daysCount <= 7) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD HH:00')}`;
                },
                offsetX: 0,
                offsetY: 0,
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
            },
            tickAmount: daysCount * 2,
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // Up to and including 14 days
    else if (daysCount >= 8 && daysCount <= 14) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD')}`;
                },
                offsetX: 0,
                offsetY: 0,
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
            },
            tickAmount: daysCount,
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // Up to and including 30 days
    else if (daysCount >= 15 && daysCount <= 30) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD')}`;
                },
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
                offsetX: 0,
                offsetY: 0,
            },
            tickAmount: (daysCount / 3).toFixed(0),
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // Up to and including 3 Months
    else if (daysCount >= 31 && daysCount <= 90) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD')}`;
                },
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
            },
            tickAmount: (daysCount / 6).toFixed(0),
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // Up to and including 6 Months
    else if (daysCount >= 91 && daysCount <= 181) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MM/DD')}`;
                },
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
            },
            tickAmount: (daysCount / 6).toFixed(0),
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // >6 Months
    else if (daysCount >= 182) {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('MMM')}`;
                },
                hideOverlappingLabels: Boolean,
                rotate: 0,
                trim: false,
            },
            tickAmount: (daysCount / 30).toFixed(0),
            axisTicks: {
                show: true,
            },
        };
        return xaxisObj;
    }

    // Default if not any
    else {
        let xaxisObj = {
            type: 'datetime',
            labels: {
                formatter: function (val, timestamp) {
                    return `${moment(timestamp).tz(timezone).format('DD/MMM')} ${moment(timestamp)
                        .tz(timezone)
                        .format('LT')}`;
                },
            },
        };
        return xaxisObj;
    }
};

export const formatXaxisForHighCharts = (daysCount, dateFormat, timeFormat, chartType = 'energy') => {
    // Up to and including 1 day
    if (daysCount === 1) {
        return timeFormat === `12h` ? '{value:%I:%M %p}' : '{value:%H:%M}';
    }

    // Up to and including 3 days
    else if (daysCount >= 2 && daysCount <= 3) {
        let value = '';
        if (dateFormat === `DD-MM-YYYY`) {
            if (timeFormat === `12h`) {
                value = '{value:%d/%m %I:%M %p}';
            } else {
                value = '{value:%d/%m %H:%M}';
            }
        } else {
            if (timeFormat === `12h`) {
                value = '{value:%m/%d %I:%M %p}';
            } else {
                value = '{value:%m/%d %H:%M}';
            }
        }
        return value;
    }

    // Up to and including 7 days
    else if (daysCount >= 4 && daysCount <= 7) {
        let value = '';
        if (dateFormat === `DD-MM-YYYY`) {
            if (timeFormat === `12h`) {
                value = '{value:%d/%m %I:%M %p}';
            } else {
                value = '{value:%d/%m %H:%M}';
            }
        } else {
            if (timeFormat === `12h`) {
                value = '{value:%m/%d %I:%M %p}';
            } else {
                value = '{value:%m/%d %H:%M}';
            }
        }
        return value;
    }

    // Up to and including 14 days
    else if (daysCount >= 8 && daysCount <= 14) {
        return dateFormat === 'DD-MM-YYYY' ? '{value:%d/%m}' : '{value:%m/%d}';
    }

    // Up to and including 30 days
    else if (daysCount >= 15 && daysCount <= 30) {
        return dateFormat === 'DD-MM-YYYY' ? '{value:%d/%m}' : '{value:%m/%d}';
    }

    // Up to and including 3 Months
    else if (daysCount >= 31 && daysCount <= 90) {
        return dateFormat === 'DD-MM-YYYY' ? '{value:%d/%m}' : '{value:%m/%d}';
    }

    // Up to and including 6 Months
    else if (daysCount >= 91 && daysCount <= 181) {
        return dateFormat === 'DD-MM-YYYY' ? '{value:%d/%m}' : '{value:%m/%d}';
    }

    // >6 Months
    else if (daysCount >= 182) {
        return chartType === 'energy' ? "{value:%b '%y}" : "{value:%e %b '%y}";
    }

    // Default if not any
    else {
        return dateFormat === 'DD-MM-YYYY' ? '{value:%d/%m}' : '{value:%m/%d}';
    }
};

export const convertDateTime = (timestamp, timeZone) => {
    return moment.utc(timestamp).clone().tz(timeZone);
};

export const handleAPIRequestBody = (start_date, end_date, time_zone, start_time, end_time) => {
    if (!start_time) {
        const newStartTime = dayjs().startOf('day');
        start_time = newStartTime.format('HH:mm');
    }

    if (!end_time) {
        const newEndTime = dayjs().endOf('day');
        end_time = newEndTime.format('HH:mm');
    }

    if (end_time.includes(':00')) {
        const endTime = moment(end_time, 'HH:mm');
        endTime.subtract(1, 'minute');
        end_time = endTime.format('HH:mm');
    }

    const dateFrom = start_date.split('T')[0].concat(`T${start_time}`);
    const dateTo = end_date.split('T')[0].concat(`T${end_time}`);

    return {
        date_from: dateFrom,
        date_to: dateTo,
        tz_info: time_zone ? time_zone : undefined,
    };
};

const getTimeInString = (type) => {
    if (type === 'startTime') {
        const newStartTime = dayjs().startOf('day');
        return newStartTime.format('HH:mm');
    }

    if (type === 'endTime') {
        const newEndTime = dayjs().endOf('day');
        return newEndTime.format('HH:mm');
    }
};

export const handleAPIRequestParams = (start_date, end_date, start_time, end_time) => {
    if (!start_time) start_time = getTimeInString('startTime');
    if (!end_time) end_time = getTimeInString('endTime');

    if (end_time.includes(':00')) {
        const endTime = moment(end_time, 'HH:mm');
        endTime.subtract(1, 'minute');
        end_time = endTime.format('HH:mm');
    }

    const dateFrom = start_date.split('T')[0].concat(`T${start_time}`);
    const dateTo = end_date.split('T')[0].concat(`T${end_time}`);

    return { dateFrom, dateTo };
};

export const isInputLetterOrNumber = (inputtxt) => {
    if (inputtxt) {
        const letterNumber = /^[0-9a-zA-Z]+$/;
        return inputtxt.match(letterNumber) ? true : false;
    }
};

export const pageListSizes = [
    {
        label: '20 Rows',
        value: '20',
    },
    {
        label: '50 Rows',
        value: '50',
    },
    {
        label: '100 Rows',
        value: '100',
    },
];

export const compareObjData = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getBuildingName = (buildingListData, id) => {
    let buildingName = '';
    if (buildingListData?.length) {
        buildingListData.forEach((building) => {
            if (building.value == id) {
                buildingName = building.label;
            }
        });
    }

    return buildingName;
};

// Below Helper function is for HeatMap time convertion
export const convertTimeTo24HourFormat = (data) => {
    const timeRegex = /(\d+)([AP]M)/;

    return data.map((item) => {
        const timeMatch = item.x.match(timeRegex);
        if (timeMatch) {
            let hour = parseInt(timeMatch[1]);
            const period = timeMatch[2];

            if (period === 'PM' && hour !== 12) {
                hour += 12;
            } else if (period === 'AM' && hour === 12) {
                hour = 0;
            }

            item.x = hour.toString().padStart(2, '0') + ':00';
        }
        return item;
    });
};

// Below Helper function is for HeatMap time convertion
export const convertTimeTo12HourFormat = (data) => {
    return data.map((item) => {
        let [hour, minute] = item.x.split(':');
        hour = parseInt(hour);
        let period = 'AM';

        if (hour === 0) {
            hour = 12;
        } else if (hour === 12) {
            period = 'PM';
        } else if (hour > 12) {
            hour -= 12;
            period = 'PM';
        }

        item.x = hour.toString().padStart(2) + period;
        return item;
    });
};

export const dateTimeFormatForHighChart = (date_format, time_format) => {
    return time_format === `12h`
        ? date_format === `DD-MM-YYYY`
            ? `%e %b '%y @ %I:%M %p`
            : `%b %e '%y @ %I:%M %p`
        : date_format === `DD-MM-YYYY`
        ? `%e %b '%y @ %H:%M`
        : `%b %e '%y @ %H:%M`;
};

export const xAxisLabelStepCount = (days_count, time_format) => {
    let value = 1;
    if (time_format === '12h' && days_count <= 7 && days_count > 1) value = 2;
    return value;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPastDateRange = (startDate, daysCount) => {
    const inputDate = moment(startDate);

    // Calculate the past date range using Moment.js while preserving timezone
    const startDatePast = inputDate.clone().subtract(daysCount, 'days');
    const endDatePast = inputDate.clone().subtract(1, 'days');

    return {
        startDate: startDatePast.toISOString(),
        endDate: endDatePast.toISOString(),
    };
};

export const downloadImage = async (node, exportName, exportExt) => {
    const downloadAs = exportExt === 'svg' ? toSvg : toPng;

    const image = await downloadAs(node, { backgroundColor: colorPalette.baseWhite });

    const link = document.createElement('a');

    link.download = exportName ? `${exportName}.${exportExt}` : `chart.${exportExt}`;

    link.href = image;

    link.click();

    link.remove();
};

export const mapSpacesList = (spaces) =>
    spaces.map((space) => {
        if (!space?.parent_space || (space?.parent_space && space?.parent_space === 'None')) space.parent_space = null;
        return space;
    });

// refer doc https://api.highcharts.com/highcharts/series.line
export const tempSeries = (data) =>
    data.map((s) => ({
        ...s,
        type: 'line',
        typeOfValue: s.type,
        group: LOW_MED_HIGH,
        marker: false,
        lineWidth: 1,
        showInLegend: false,
        yAxis: 1,
        zIndex: 2,
        states: {
            hover: {
                enabled: false,
            },
            inactive: {
                opacity: 1,
            },
        },
    }));

export const handleJiraWidgetVisibility = (value = 'hidden') => {
    const widget = document.getElementById('jsd-widget');
    if (widget) {
        widget.style.visibility = value;
    }
};

export const getDifferences = (obj1, obj2) =>
    _.reduce(
        obj1,
        (acc, value, key) => {
            if (!_.isEqual(value, obj2[key])) {
                acc[key] = value;
            }
            return acc;
        },
        {}
    );

export const checkArray = (possibleArray) => Array.isArray(possibleArray) && possibleArray.length > 0;

export const getFileExtension = (filePath) => {
    if (!filePath) return null;

    const fileName = filePath.split('/').pop();
    const extension = fileName.split('.').pop();

    if (fileName === extension) {
        return null;
    }

    return extension;
};

export const checkObject = (possibleObject) =>
    typeof possibleObject === 'object' && Object.keys(possibleObject).length > 0;

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export const handleTimestampFormat = (timestamp, userPrefDateFormat, userPrefTimeFormat) => {
    let dt = '';
    if (isValidDate(new Date(timestamp)) && timestamp != null) {
        const last_dt = new Date(timestamp);
        const dateFormat = userPrefDateFormat === `DD-MM-YYYY` ? `D MMM` : `MMM D`;
        const timeFormat = userPrefTimeFormat === `12h` ? `hh:mm A` : `HH:mm`;
        dt = moment.utc(last_dt).format(`${dateFormat} 'YY @ ${timeFormat}`);
    } else {
        dt = 'Never';
    }
    return dt;
};
