import axiosInstance from '../../services/axiosInstance';
import {
    getEnergyConsumptionBySpaceV2,
    getEnergyConsumptionSpaceByCategory,
    getSpaceMetadataV2,
    getEquipmentBySpace,
    getEquipmentFiltersBySpace,
} from '../../services/Network';

const parseQuery = (queryObj) => {
    const {
        bldgId = '',
        dateFrom = '',
        dateTo = '',
        tzInfo = 'US/Eastern',
        search,
        spaceTypeIds,
        equipmentTypeIds,
        endUsesCategoriesIds,
        selectedTags,
        minConsumption,
        maxConsumption,
    } = queryObj;

    let query = `?building_id=${bldgId}&date_from=${dateFrom}&date_to=${dateTo}&tz_info=${tzInfo}`;

    if (search) query += `&search=${search}`;

    if (spaceTypeIds && spaceTypeIds.length !== 0) {
        const stringSpaceTypeId = spaceTypeIds.join('+');
        query += `&space_type_ids=${encodeURIComponent(stringSpaceTypeId)}`;
    }

    if (equipmentTypeIds && equipmentTypeIds.length !== 0) {
        const stringEquipmentTypeId = equipmentTypeIds.join('+');
        query += `&equipment_type_ids=${encodeURIComponent(stringEquipmentTypeId)}`;
    }

    if (endUsesCategoriesIds && endUsesCategoriesIds.length !== 0) {
        const stringEndUsesId = endUsesCategoriesIds.join('+');
        query += `&end_use_ids=${encodeURIComponent(stringEndUsesId)}`;
    }

    if (selectedTags && selectedTags.length !== 0) {
        const stringTags = selectedTags.join('+');
        query += `&tags=${encodeURIComponent(stringTags)}`;
    }

    if (isFinite(minConsumption)) query += `&consumption_range_gte=${Number(minConsumption) * 1000}`;
    if (isFinite(maxConsumption)) query += `&consumption_range_lte=${Number(maxConsumption) * 1000}`;

    return query;
};

export const fetchEnergyConsumptionSpaceByCategory = async (category, query) => {
    const { spaceId = '', bldgId = '', dateFrom = '', dateTo = '', tzInfo = 'US/Eastern' } = query;

    const params = `?space_ids=${spaceId}&building_id=${bldgId}&date_from=${dateFrom}&date_to=${dateTo}&tz_info=${tzInfo}`;

    return axiosInstance.get(`${getEnergyConsumptionSpaceByCategory}/${category}${params}`).then((res) => res?.data);
};

export const fetchEnergyConsumptionBySpace = async (query) => {
    const { spaceId = '', bldgId = '', dateFrom = '', dateTo = '', tzInfo = 'US/Eastern' } = query;

    const params = `?space_ids=${spaceId}&building_id=${bldgId}&date_from=${dateFrom}&date_to=${dateTo}&tz_info=${tzInfo}`;

    return axiosInstance.get(`${getEnergyConsumptionBySpaceV2}/${params}`).then((res) => res?.data);
};

export const fetchSpaceMetadata = async (query, spaceId) => {
    const { bldgId = '', dateFrom = '', dateTo = '', tzInfo = 'US/Eastern' } = query;

    const params = `?building_id=${bldgId}&date_from=${dateFrom}&date_to=${dateTo}&tz_info=${tzInfo}`;

    return axiosInstance.get(`${getSpaceMetadataV2}/${spaceId}${params}`).then((res) => res?.data);
};

export const fetchEquipmentBySpace = async (spaceId, queryObj) => {
    let query = parseQuery(queryObj);

    if (spaceId) query += `&space_ids=${spaceId}`;

    const { page = 1, size = 20, orderBy, sortedBy } = queryObj;

    query += `&page_no=${page}&page_size=${size}`;

    if (orderBy) query += `&ordered_by=${orderBy}`;
    if (sortedBy) query += `&sort_by=${sortedBy}`;

    return axiosInstance.get(`${getEquipmentBySpace}${query}`).then((res) => res?.data);
};

export const fetchEquipmentFiltersBySpace = async (spaceId, queryObj) => {
    let query = parseQuery(queryObj);

    if (spaceId) query += `&space_ids=${spaceId}`;

    return axiosInstance.get(`${getEquipmentFiltersBySpace}${query}`).then((res) => res?.data);
};
