import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'reactstrap';

import Brick from '../../../../sharedComponents/brick';
import Button from '../../../../sharedComponents/button/Button';
import Typography from '../../../../sharedComponents/typography';
import Input from '../../../../sharedComponents/form/input/Input';
import InputTooltip from '../../../../sharedComponents/form/input/InputTooltip';

import { ReactComponent as EyeSVG } from '../../../../assets/icon/eye.svg';
import { ReactComponent as EyeSlashSVG } from '../../../../assets/icon/eye-slash.svg';

import colorPalette from '../../../../assets/scss/_colors.module.scss';
import './style.css';

const LinkModal = ({ showlink, handleLinkClose, error, message, handleAuthorize }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordType, setPasswordType] = useState('password');

    const Submit = () => {
        let ct = 0;

        if (email.length >= 0 && !/^\w+([\+.-]?\w+)*@\w+([\+.-]?\w+)*(\.\w{2,11})+$/.test(email)) {
            setEmailError('Please Enter a Valid Email');
            ct++;
        }
        if (password.length < 4) {
            setPasswordError(true);
            ct++;
        }

        if (ct === 0) handleAuthorize(email, password);
    };

    return (
        <Modal show={showlink} onHide={handleLinkClose} backdrop="static" keyboard={false} centered>
            <div className="p-4">
                <Typography.Header size={Typography.Sizes.lg}>{`Add TP Link - Kasa Account`}</Typography.Header>

                <Brick sizeInRem={2} />

                {error && (
                    <>
                        <Alert color="danger" isOpen={error}>
                            {message}
                        </Alert>
                        <Brick sizeInRem={0.5} />
                    </>
                )}

                <Typography.Body size={Typography.Sizes.md}>
                    Email
                    <span style={{ color: colorPalette.error600 }} className="font-weight-bold ml-1">
                        *
                    </span>
                </Typography.Body>
                <Brick sizeInRem={0.25} />
                <InputTooltip
                    placeholder="hello@Sapient.industries"
                    labelsize={Typography.Sizes.md}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    error={emailError}
                />

                <Brick sizeInRem={1.25} />

                <div>
                    <Typography.Body size={Typography.Sizes.md}>
                        Password
                        <span style={{ color: colorPalette.error600 }} className="font-weight-bold ml-1">
                            *
                        </span>
                    </Typography.Body>
                    <Brick sizeInRem={0.25} />
                    <Input
                        labelSize={Typography.Sizes.md}
                        placeholder="Enter your password"
                        type={passwordType}
                        elementEnd={
                            passwordType === 'password' ? (
                                <EyeSVG
                                    onClick={() => {
                                        setPasswordType('text');
                                    }}
                                />
                            ) : (
                                <EyeSlashSVG
                                    onClick={() => {
                                        setPasswordType('password');
                                    }}
                                />
                            )
                        }
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        value={password}
                        error={passwordError ? 'Password is required' : null}
                    />
                </div>

                <Brick sizeInRem={2} />

                <Modal.Footer className="p-0">
                    <Button
                        label="Cancel"
                        size={Button.Sizes.lg}
                        type={Button.Type.secondaryGrey}
                        className="w-100"
                        onClick={() => {
                            setEmail('');
                            setPassword('');
                            setEmailError(null);
                            setPasswordError(false);
                            handleLinkClose();
                        }}
                    />

                    <Button
                        label={'Add'}
                        size={Button.Sizes.lg}
                        type={Button.Type.primary}
                        className="w-100"
                        onClick={() => {
                            Submit();
                        }}
                    />
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default LinkModal;
