import Typography from './../sharedComponents/typography';
import Switch from 'react-switch';
import { UncontrolledTooltip } from 'reactstrap';
import { ReactComponent as TooltipIcon } from './../sharedComponents/assets/icons/tooltip.svg';
import Brick from './../sharedComponents/brick';

const ToggleSetting = ({
    title,
    description,
    tooltipImage,
    settingKey,
    checked,
    handleChange,
    isDisabled,
    imageWidth,
    imageHeight,
    objectFill,
}) => {
    return (
        <div className="row">
            <div className="col">
                <UncontrolledTooltip
                    placement="right"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    }}
                    target={'tooltip-' + settingKey}>
                    <img
                        src={tooltipImage}
                        alt="Tooltip Image"
                        style={{
                            backgroundColor: 'transparent',
                            display: 'block',
                            width: imageWidth ? imageWidth : '450px',
                            height: imageHeight ? imageHeight : 'auto',
                            objectFit: objectFill ? objectFill : 'contain',
                        }}
                    />
                </UncontrolledTooltip>
                <Typography.Subheader size={Typography.Sizes.md}>
                    {title}
                    <button type="button" className="KPI-component-tooltip-button" id={'tooltip-' + settingKey}>
                        <TooltipIcon className="KPI-component-tooltip-icon" />
                    </button>
                </Typography.Subheader>
                <Brick sizeInRem={0.25} />
                <Typography.Body size={Typography.Sizes.sm}>{description}</Typography.Body>
            </div>
            <div className="col d-flex align-items-center">
                <Switch
                    checked={checked}
                    onColor={'#007bff'}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={(e) => handleChange(settingKey, e)}
                    className="react-switch"
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};

export default ToggleSetting;
