import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { UncontrolledTooltip } from 'reactstrap';

import { UserStore } from '../../../store/UserStore';

import Typography from '../../../sharedComponents/typography';
import Brick from '../../../sharedComponents/brick';
import { Button } from '../../../sharedComponents/button';
import InputTooltip from '../../../sharedComponents/form/input/InputTooltip';
import Select from '../../../sharedComponents/form/select';

import { ReactComponent as PlusSVG } from '../../../assets/icon/plus.svg';
import { ReactComponent as TooltipIcon } from '../../../sharedComponents/assets/icons/tooltip.svg';

import { UTILITY_MONITOR, convertToAlphaNumeric, utilityMeterModel } from './utils';
import { createUtilityMeterServices } from './services';

import colorPalette from '../../../assets/scss/_colors.module.scss';

const CreateUtilityMeters = (props) => {
    const { bldgId, fetchUtilityMetersWithFilter } = props;

    const history = useHistory();

    const defaultObj = {
        status: true,
        device_id: '',
        model: UTILITY_MONITOR.PULSE_COUNTER,
        model_name: 'Sapient Pulse',
        modbus: '',
        name: '',
    };

    const defaultError = {
        device_id: null,
        model: null,
        modbus: null,
    };

    const [modal, setModal] = useState(false);
    const handleModalOpen = () => setModal(true);
    const handleModalClose = () => setModal(false);

    const [utilityData, setUtilityData] = useState(defaultObj);
    const [utilityError, setUtilityError] = useState(defaultError);

    const [isProcessing, setIsProcessing] = useState(false);

    const handleChange = (key, value) => {
        let obj = Object.assign({}, utilityData);

        if (key === 'device_id') {
            let formattedValue = convertToAlphaNumeric(value);
            if (formattedValue.length > 12) return;
            formattedValue = formattedValue.replace(/..\B/g, '$&:');
            obj[key] = formattedValue;
        } else {
            obj[key] = value;
        }

        if (key === 'model') {
            let selectedObj = utilityMeterModel.find((el) => el?.value === value);
            obj.model_name = selectedObj?.label;
        }
        setUtilityData(obj);
    };

    const redirectUserToUtilityMeterPage = (deviceId) => {
        history.push({
            pathname: `/settings/utility-monitors/single/${bldgId}/${deviceId}`,
        });
    };

    const handleClose = () => {
        setIsProcessing(false);
        setUtilityData(defaultObj);
        setUtilityError(defaultError);
        handleModalClose();
    };

    const saveUtilityMeter = async () => {
        let alertObj = Object.assign({}, utilityError);

        let formattedDeviceId = convertToAlphaNumeric(utilityData?.device_id);

        if (formattedDeviceId.length < 12) alertObj.device_id = 'Please enter 12 digit Device ID.';
        if (utilityData?.model.length === 0) alertObj.model = { text: 'Please select Model.' };
        if (utilityData?.modbus.length === 0) alertObj.modbus = 'Please enter Modbus address. It cannot be empty.';

        setUtilityError(alertObj);

        if (!alertObj.device_id && !alertObj.model && !alertObj.modbus) {
            setIsProcessing(true);

            const reqObj = {
                building_id: bldgId,
                device_type: utilityData?.model.includes(`shadow`) ? 'shadow meter' : utilityData?.model,
                model: utilityData?.model_name,
                deviceIdentifier: utilityData?.device_id,
                modbus_address: utilityData?.modbus,
                name: utilityData?.name,
            };

            await createUtilityMeterServices(reqObj)
                .then((res) => {
                    const response = res?.data;

                    if (response?.success) {
                        UserStore.update((s) => {
                            s.showNotification = true;
                            s.notificationMessage = response?.message ?? 'Device created successfully.';
                            s.notificationType = 'success';
                        });

                        handleClose();

                        const isPowerScout = utilityData?.model === UTILITY_MONITOR.POWER_SCOUT;
                        const hasDeviceId = response?.data?.device_id;

                        if (isPowerScout || !hasDeviceId) {
                            fetchUtilityMetersWithFilter();
                        } else {
                            redirectUserToUtilityMeterPage(response.data.device_id);
                        }
                    } else {
                        const isSuccess = response?.success;
                        const hasErrorMessage = response?.message.includes('identifier');
                        const message =
                            response?.message ||
                            (res
                                ? 'Unable to create Utility Monitor.'
                                : 'Unable to create Utility Monitor due to Internal Server Error!.');

                        if (!isSuccess && hasErrorMessage) {
                            alertObj.device_id = 'Device ID with given name already exists.';
                            setUtilityError(alertObj);
                        } else {
                            UserStore.update((s) => {
                                s.showNotification = true;
                                s.notificationMessage = message;
                                s.notificationType = 'error';
                            });
                        }
                    }
                    setIsProcessing(false);
                })
                .catch((e) => {
                    setIsProcessing(false);
                    handleClose();
                });
        }
    };

    const ModbusAddressToolTip = ({ modelType }) => {
        let message = '';

        switch (modelType) {
            case UTILITY_MONITOR.PULSE_COUNTER:
                message = `Clicking ‘create’ will initiate the creation of 8 Pulse inputs on an A8832 device, the standard Modbus address is 2.`;
                break;
            case UTILITY_MONITOR.SHADOW_50:
                message = `Clicking ‘create’ will initiate the creation of a single Shark 50 series device, the standard Modbus address is 6.`;
                break;
            case UTILITY_MONITOR.SHADOW_100:
                message = `Clicking ‘create’ will initiate the creation of a single Shark 100 series device, the standard Modbus address is 6.`;
                break;
            case UTILITY_MONITOR.SHADOW_250:
                message = `Clicking ‘create’ will initiate the creation of a single Shark 200 series device, the standard Modbus address is 6.`;
                break;
            case UTILITY_MONITOR.POWER_SCOUT:
                message = `Clicking ‘create’ will initiate the creation of 16 x unique devices, with sequential Modbus Addresses starting with the Modbus address entered.`;
                break;
            default:
                break;
        }

        return (
            <div>
                <UncontrolledTooltip placement="top" target="tooltip-for-modbus-address">
                    {message}
                </UncontrolledTooltip>

                <button type="button" className="tooltip-button" id="tooltip-for-modbus-address">
                    <TooltipIcon className="tooltip-icon" />
                </button>
            </div>
        );
    };

    return (
        <>
            <div className="d-flex">
                <Button
                    label={isProcessing ? 'Adding Utility Monitor ...' : 'Add Utility Monitor'}
                    size={Button.Sizes.md}
                    type={Button.Type.primary}
                    icon={<PlusSVG />}
                    disabled={isProcessing}
                    onClick={handleModalOpen}
                />
            </div>
            <Modal show={modal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                <div className="p-4">
                    <Typography.Header size={Typography.Sizes.lg}>Add Utility Monitor</Typography.Header>

                    <Brick sizeInRem={2} />

                    <div>
                        <div className="d-flex align-items-center">
                            <Typography.Body size={Typography.Sizes.md}>Device Name</Typography.Body>
                        </div>
                        <Brick sizeInRem={0.25} />
                        <InputTooltip
                            placeholder={`Enter Device Name`}
                            type="text"
                            onChange={(e) => {
                                if (e.target.value < 0) return;
                                handleChange('name', e.target.value);
                            }}
                            // error={utilityError?.modbus}
                            labelsize={Typography.Sizes.md}
                            value={utilityData?.name}
                            inputClassName={'custom-input-field'}
                        />
                    </div>

                    <Brick sizeInRem={1.25} />

                    <div>
                        <Typography.Body size={Typography.Sizes.md}>
                            Model
                            <span style={{ color: colorPalette.error600 }} className="font-weight-bold ml-1">
                                *
                            </span>
                        </Typography.Body>
                        <Brick sizeInRem={0.25} />
                        <Select
                            placeholder="Select Model"
                            options={utilityMeterModel}
                            defaultValue={utilityMeterModel.filter((option) => option.value === utilityData?.model)}
                            onChange={(e) => {
                                handleChange('model', e.value);
                                setUtilityError({ ...utilityError, model: null });
                            }}
                            error={utilityError?.model}
                            isSearchable={true}
                        />
                    </div>

                    <Brick sizeInRem={1.5} />

                    <div>
                        <Typography.Body size={Typography.Sizes.md}>
                            Device ID
                            <span style={{ color: colorPalette.error600 }} className="font-weight-bold ml-1">
                                *
                            </span>
                        </Typography.Body>
                        <Brick sizeInRem={0.25} />
                        <InputTooltip
                            placeholder="Enter Device ID"
                            onChange={(e) => {
                                handleChange('device_id', e.target.value.trim().toUpperCase());
                                setUtilityError({ ...utilityError, device_id: null });
                            }}
                            error={utilityError?.device_id}
                            labelsize={Typography.Sizes.md}
                            value={utilityData?.device_id}
                        />
                        <Brick sizeInRem={0.25} />
                        {!utilityError.device_id && (
                            <Typography.Body size={Typography.Sizes.sm}>Enter MAC address of A8810.</Typography.Body>
                        )}
                    </div>

                    <Brick sizeInRem={1.25} />

                    <div>
                        <div className="d-flex align-items-center">
                            <Typography.Body size={Typography.Sizes.md}>
                                {`${
                                    utilityData?.model === UTILITY_MONITOR.POWER_SCOUT ? 'Starting' : ''
                                } Modbus Address`}
                            </Typography.Body>
                            <ModbusAddressToolTip modelType={utilityData?.model} />
                            <span style={{ color: colorPalette.error600 }} className="font-weight-bold ml-1">
                                *
                            </span>
                        </div>
                        <Brick sizeInRem={0.25} />
                        <InputTooltip
                            placeholder={`Enter ${
                                utilityData?.model === UTILITY_MONITOR.POWER_SCOUT ? 'starting ' : ''
                            }Modbus Address`}
                            type="number"
                            onChange={(e) => {
                                if (e.target.value < 0) return;
                                handleChange('modbus', e.target.value);
                                setUtilityError({ ...utilityError, modbus: null });
                            }}
                            error={utilityError?.modbus}
                            labelsize={Typography.Sizes.md}
                            value={utilityData?.modbus}
                            inputClassName={'custom-input-field'}
                        />
                        {utilityData?.model === UTILITY_MONITOR.POWER_SCOUT && (
                            <>
                                <Brick sizeInRem={0.25} />
                                <Typography.Body size={Typography.Sizes.sm}>
                                    Standard Modbus addressing:
                                </Typography.Body>
                                <Typography.Body size={Typography.Sizes.sm}>
                                    Unit 1 - Modbus addresses 14 to 29
                                </Typography.Body>
                                <Typography.Body size={Typography.Sizes.sm}>
                                    Unit 2 - Modbus addresses 30 to 45
                                </Typography.Body>
                            </>
                        )}
                        {(utilityData?.model === UTILITY_MONITOR.SHADOW_50 ||
                            utilityData?.model === UTILITY_MONITOR.SHADOW_100 ||
                            utilityData?.model === UTILITY_MONITOR.SHADOW_250) && (
                            <>
                                <Brick sizeInRem={0.25} />
                                <Typography.Body size={Typography.Sizes.sm}>
                                    Standard Modbus address is 6
                                </Typography.Body>
                            </>
                        )}
                        {utilityData?.model === UTILITY_MONITOR.PULSE_COUNTER && (
                            <>
                                <Brick sizeInRem={0.25} />
                                <Typography.Body size={Typography.Sizes.sm}>
                                    Standard Modbus address is 2
                                </Typography.Body>
                            </>
                        )}
                    </div>

                    <Brick sizeInRem={1.5} />

                    <div className="d-flex justify-content-between w-100">
                        <Button
                            label="Cancel"
                            size={Button.Sizes.lg}
                            type={Button.Type.secondaryGrey}
                            className="w-100"
                            onClick={() => {
                                setUtilityData(defaultObj);
                                setUtilityError(defaultError);
                                handleModalClose();
                            }}
                        />

                        <Button
                            label={isProcessing ? 'Creating...' : 'Create'}
                            size={Button.Sizes.lg}
                            type={Button.Type.primary}
                            className="w-100"
                            disabled={isProcessing}
                            onClick={saveUtilityMeter}
                        />
                    </div>

                    <Brick sizeInRem={1} />
                </div>
            </Modal>
        </>
    );
};

export default CreateUtilityMeters;
