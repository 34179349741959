// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import { Provider } from 'react-redux';
// import { configureStore } from './redux/store';
// import { BrowserRouter } from 'react-router-dom';
// import { QueryClient, QueryClientProvider } from 'react-query';
// import { NotificationProvider } from './sharedComponents/notification/NotificationProvider';

// const queryClient = new QueryClient();

// ReactDOM.render(
//     <QueryClientProvider client={queryClient}>
//         <Provider store={configureStore()}>
//             <NotificationProvider>
//                 <BrowserRouter>
//                     <App />
//                 </BrowserRouter>
//             </NotificationProvider>
//         </Provider>
//     </QueryClientProvider>,
//     document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from react-dom/client
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationProvider } from './sharedComponents/notification/NotificationProvider';

const queryClient = new QueryClient();

// Create root
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={configureStore()}>
                <NotificationProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </NotificationProvider>
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>
);

// Service worker
serviceWorker.unregister();
