import React, { useEffect, useState, useCallback } from 'react';
import Brick from '../../sharedComponents/brick';
import { Progress, UncontrolledTooltip } from 'reactstrap';
import { DataTableWidget } from '../../sharedComponents/dataTableWidget';
import SkeletonLoader from '../../components/SkeletonLoader';
import { formatConsumptionValue, handleAPIRequestParams, pageListSizes } from '../../helpers/helpers';
import Typography from '../../sharedComponents/typography';
import { UNITS } from '../../constants/units';
import { TrendsBadge } from '../../sharedComponents/trendsBadge';
import { DateRangeStore } from '../../store/DateRangeStore';
import { BuildingStore } from '../../store/BuildingStore';
import { UserStore } from '../../store/UserStore';
import { useParams } from 'react-router-dom';
import useCSVDownload from '../../sharedComponents/hooks/useCSVDownload';
import { getEquipmentSpaceModuleTableCSVExport } from '../../utils/tablesExport';
import { fetchEquipmentBySpace, fetchEquipmentFiltersBySpace } from './services';
import { Badge } from '../../sharedComponents/badge';
import EquipChartModal from '../chartModal/EquipChartModal';
import { FILTER_TYPES } from '../../sharedComponents/dataTableWidget/constants';
import _ from 'lodash';

const EquipmentTable = ({ spaceId }) => {
    const { download } = useCSVDownload();
    const startDate = DateRangeStore.useState((s) => s.startDate);
    const endDate = DateRangeStore.useState((s) => s.endDate);
    const startTime = DateRangeStore.useState((s) => s.startTime);
    const endTime = DateRangeStore.useState((s) => s.endTime);

    const timeZone = BuildingStore.useState((s) => s.BldgTimeZone);
    const bldgName = BuildingStore.useState((s) => s.BldgName);
    const userPrefUnits = UserStore.useState((s) => s.unit);

    const { bldgId } = useParams();

    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalItems, setTotalItems] = useState(0);
    const [equipments, setEquipments] = useState([]);
    const [equipmentsLoading, setEquipmentsLoading] = useState([]);
    const [isCSVDownloading, setDownloadingCSVData] = useState(false);

    const [showEquipmentChart, setShowEquipmentChart] = useState(false);
    const handleChartOpen = () => setShowEquipmentChart(true);
    const handleChartClose = () => setShowEquipmentChart(false);
    const [equipmentFilter, setEquipmentFilter] = useState({});
    const [selectedModalTab, setSelectedModalTab] = useState(0);

    // filters
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [spaceTypeIds, setSpaceTypeIds] = useState([]);
    const [equipmentTypeIds, setEquipmentTypeIds] = useState([]);
    const [endUsesCategoriesIds, setEndUsesCategoriesIds] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [minConsumption, setMinConsumption] = useState(0);
    const [maxConsumption, setMaxConsumption] = useState(0);
    const [flagAPIConsumption, setFlagAPIConsumption] = useState(0);

    const getFilters = (query) => {
        if (Array.isArray(spaceTypeIds) && spaceTypeIds.length > 0) query.spaceTypeIds = spaceTypeIds;
        if (Array.isArray(equipmentTypeIds) && equipmentTypeIds.length > 0) query.equipmentTypeIds = equipmentTypeIds;
        if (Array.isArray(endUsesCategoriesIds) && endUsesCategoriesIds.length > 0)
            query.endUsesCategoriesIds = endUsesCategoriesIds;
        if (Array.isArray(selectedTags) && selectedTags.length > 0) query.selectedTags = selectedTags;

        if (isFinite(minConsumption) && isFinite(maxConsumption) && maxConsumption !== 0) {
            query.minConsumption = minConsumption;
            query.maxConsumption = maxConsumption;
        }

        if (search) query.search = search;

        return query;
    };

    const fetchEquipDataList = async () => {
        setEquipmentsLoading(true);

        try {
            const orderedBy = sortBy.name === undefined || sortBy.method === null ? 'consumption' : sortBy.name;
            const sortedBy = sortBy.method === undefined || sortBy.method === null ? 'dce' : sortBy.method;

            const { dateFrom, dateTo } = handleAPIRequestParams(startDate, endDate, startTime, endTime);

            const query = {
                bldgId,
                page: pageNo,
                size: pageSize,
                dateFrom: encodeURIComponent(dateFrom),
                dateTo: encodeURIComponent(dateTo),
                timeZone,
                orderedBy,
                sortedBy,
            };

            const queryWithFilters = getFilters(query);

            const data = await fetchEquipmentBySpace(spaceId, queryWithFilters);

            if (data && Array.isArray(data) && data.length !== 0) {
                // should be done on backend
                const totalConsumption = data.reduce((reducer, equipment) => {
                    return reducer + Math.round(equipment?.total_consumption?.now);
                }, 0);

                const mappedData = data.map((equipment) => {
                    equipment.total_all_consumption = totalConsumption;
                    return equipment;
                });

                setTotalItems(mappedData.length);
                setEquipments(mappedData);
            }
        } catch {
            setEquipments([]);
            setTotalItems(0);
        }

        setEquipmentsLoading(false);
    };

    const filterHandler = (setter, options) => {
        setter(options.map(({ value }) => value));
        setPageNo(1);
    };

    const fetchFilters = async () => {
        setLoadingFilters(true);

        try {
            const { dateFrom, dateTo } = handleAPIRequestParams(startDate, endDate, startTime, endTime);

            const query = {
                bldgId,
                dateFrom: encodeURIComponent(dateFrom),
                dateTo: encodeURIComponent(dateTo),
                tzInfo: timeZone,
                spaceTypeIds,
            };

            const queryWithFilters = getFilters(query);

            const response = await fetchEquipmentFiltersBySpace(spaceId, queryWithFilters);

            const filterOptionsServer = response[0];

            const filterOptions = [];

            if (isFinite(filterOptionsServer?.min_new_energy) && isFinite(filterOptionsServer?.max_new_energy)) {
                const minResponseNewEnergy = Math.abs(Math.round(filterOptionsServer.min_new_energy / 1000));
                const maxResponseNewEnergy = Math.abs(Math.round(filterOptionsServer.max_new_energy / 1000));

                filterOptions.push({
                    label: 'Energy Consumption',
                    value: 'consumption',
                    placeholder: 'All Consumptions',
                    filterType: FILTER_TYPES.RANGE_SELECTOR,
                    filterOptions: [minResponseNewEnergy, maxResponseNewEnergy],
                    componentProps: {
                        prefix: ' kWh',
                        title: 'Consumption',
                        min: minResponseNewEnergy,
                        max: maxResponseNewEnergy,
                        range: [minResponseNewEnergy, maxResponseNewEnergy],
                        withTrendsFilter: false,
                    },
                    onClose: async function onClose(options) {
                        setMinConsumption(options[0]);
                        setMaxConsumption(options[1]);
                        setFlagAPIConsumption(options[0] + options[1]);
                    },
                    onDelete: () => {
                        setMinConsumption(0);
                        setMaxConsumption(0);
                        setFlagAPIConsumption(0);
                        setPageNo(1);
                    },
                });
            }

            if (Array.isArray(filterOptionsServer?.space_types) && filterOptionsServer.space_types.length > 0)
                filterOptions.push({
                    label: 'Space Type',
                    value: 'space_types',
                    placeholder: 'All Space Types',
                    filterType: FILTER_TYPES.MULTISELECT,
                    filterOptions: _.chain(filterOptionsServer?.space_types)
                        .sortBy('space_type_name')
                        .map((filterItem) => ({
                            value: filterItem?.space_type_id,
                            label: filterItem?.space_type_name,
                        }))
                        .value(),
                    onClose: (options) => filterHandler(setSpaceTypeIds, options),
                    onDelete: () => {
                        setPageNo(1);
                        setSpaceTypeIds([]);
                    },
                });

            if (Array.isArray(filterOptionsServer?.equipment_types) && filterOptionsServer.equipment_types.length > 0)
                filterOptions.push({
                    label: 'Equipment Type',
                    value: 'equipment_types',
                    placeholder: 'All Equipment Types',
                    filterType: FILTER_TYPES.MULTISELECT,
                    filterOptions: _.chain(filterOptionsServer?.equipment_types)
                        .sortBy('equipment_type_name')
                        .map((filterItem) => ({
                            value: filterItem?.equipment_type_id,
                            label: filterItem?.equipment_type_name,
                        }))
                        .value(),
                    onClose: (options) => filterHandler(setEquipmentTypeIds, options),
                    onDelete: () => {
                        setPageNo(1);
                        setEquipmentTypeIds([]);
                    },
                });

            if (Array.isArray(filterOptionsServer?.end_uses) && filterOptionsServer.end_uses.length > 0)
                filterOptions.push({
                    label: 'End Use Category',
                    value: 'end_uses',
                    placeholder: 'All End Use Categories',
                    filterType: FILTER_TYPES.MULTISELECT,
                    filterOptions: _.chain(filterOptionsServer?.end_uses)
                        .sortBy('end_use_name')
                        .map((filterItem) => ({
                            value: filterItem?.end_use_id,
                            label: filterItem?.end_use_name,
                        }))
                        .value(),
                    onClose: (options) => filterHandler(setEndUsesCategoriesIds, options),
                    onDelete: () => {
                        setPageNo(1);
                        setEndUsesCategoriesIds([]);
                    },
                });

            if (Array.isArray(filterOptionsServer?.tags) && filterOptionsServer.tags.length > 0)
                filterOptions.push({
                    label: 'Tags',
                    value: 'tags',
                    placeholder: 'All tags',
                    filterType: FILTER_TYPES.MULTISELECT,
                    filterOptions: _.chain(filterOptionsServer?.tags)
                        .sort()
                        .map((filterItem) => ({
                            value: filterItem,
                            label: filterItem,
                        }))
                        .value(),
                    onClose: (options) => {
                        let opt = options;
                        if (opt.length !== 0) {
                            let list = [];
                            for (let i = 0; i < opt.length; i++) {
                                list.push(opt[i].value);
                            }
                            setSelectedTags(list);
                        }
                    },
                    onDelete: () => {
                        setPageNo(1);
                        setSelectedTags([]);
                    },
                });

            setFilterOptions(filterOptions);
        } catch {
            setFilterOptions([]);
        }

        setLoadingFilters(false);
    };

    useEffect(() => {
        if (!bldgId || !startDate || !endDate) return;

        fetchFilters();
        fetchEquipDataList();
    }, [
        startDate,
        endDate,
        startTime,
        endTime,
        bldgId,
        search,
        sortBy,
        pageSize,
        pageNo,
        userPrefUnits,
        spaceTypeIds,
        equipmentTypeIds,
        endUsesCategoriesIds,
        selectedTags,
        flagAPIConsumption,
    ]);

    const handleDownloadCSV = async () => {
        setDownloadingCSVData(true);

        try {
            if (equipments.length !== 0) {
                download(
                    `LinkedEquipmentTable_${bldgName}_${new Date().toISOString().split('T')[0]}`,
                    getEquipmentSpaceModuleTableCSVExport(equipments, headerProps)
                );
                UserStore.update((s) => {
                    s.showNotification = true;
                    s.notificationMessage = 'CSV export completed successfully.';
                    s.notificationType = 'success';
                });
            }
        } catch {
            UserStore.update((s) => {
                s.showNotification = true;
                s.notificationMessage = 'Data failed to export in CSV.';
                s.notificationType = 'error';
            });
        }
        setDownloadingCSVData(false);
    };

    const renderName = useCallback((row) => {
        const handleClick = () => {
            setEquipmentFilter({
                equipment_id: row?.equipment_id,
                equipment_name: row?.equipment_name,
                device_type: row?.device_type,
            });
            handleChartOpen();
        };

        return (
            <button onClick={handleClick}>
                <p className="equip-name-column">
                    <u>{row?.equipment_name !== '' ? row?.equipment_name : '-'}</u>
                </p>
            </button>
        );
    });

    const renderConsumption = useCallback((row) => {
        return (
            <>
                <Typography.Body size={Typography.Sizes.sm}>
                    {`${formatConsumptionValue(Math.round(row?.total_consumption?.now / 1000))} ${UNITS.KWH}`}
                </Typography.Body>
                <Brick sizeInRem={0.375} />
                <Progress multi className="custom-progress-bar" style={{ height: '6px' }}>
                    <Progress
                        bar
                        value={row?.total_consumption?.now}
                        max={row?.total_all_consumption}
                        barClassName="custom-on-hour"
                    />
                </Progress>
            </>
        );
    });

    const calculatePercentageChange = (oldValue, newValue) => {
        const calculatedOldValue = oldValue > 0 ? oldValue : 1;
        const calculatedNewValue = newValue > 0 ? newValue : 1;

        return ((calculatedNewValue - calculatedOldValue) / calculatedOldValue) * 100;
    };

    const renderPerChange = useCallback((row) => {
        const change = calculatePercentageChange(row?.total_consumption?.old, row?.total_consumption?.now);

        return (
            Number.isFinite(change) && (
                <TrendsBadge
                    value={Math.abs(Math.round(change))}
                    type={
                        change === 0
                            ? TrendsBadge.Type.NEUTRAL_TREND
                            : row?.total_consumption?.now < row?.total_consumption?.old
                            ? TrendsBadge.Type.DOWNWARD_TREND
                            : TrendsBadge.Type.UPWARD_TREND
                    }
                />
            )
        );
    });

    const renderTags = useCallback((row) => {
        const tags = Array.isArray(row?.tags) && row.tags.length > 0 ? row.tags : [];
        const slicedArr = tags.slice(1);

        return (
            <div className="tag-row-content">
                <Badge text={<span className="gray-950">{tags[0] ? tags[0] : 'none'}</span>} />
                {slicedArr?.length > 0 ? (
                    <>
                        <Badge
                            text={
                                <span className="gray-950" id={`tags-badge-${row?.equipment_id}`}>
                                    +{slicedArr.length} more
                                </span>
                            }
                        />
                        <UncontrolledTooltip
                            placement="top"
                            target={`tags-badge-${row?.equipment_id}`}
                            className="tags-tooltip">
                            {slicedArr.map((el) => {
                                return <Badge text={<span className="gray-950">{el}</span>} />;
                            })}
                        </UncontrolledTooltip>
                    </>
                ) : null}
            </div>
        );
    });

    const headerProps = [
        {
            name: 'Name',
            accessor: 'equipment_name',
            callbackValue: renderName,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Energy Consumption',
            accessor: 'consumption',
            callbackValue: renderConsumption,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: '% Change',
            accessor: 'change',
            callbackValue: renderPerChange,
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Space Type',
            accessor: 'space_type_name',
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Equipment Type',
            accessor: 'equipment_type_name',
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'End Use Category',
            accessor: 'end_use_name',
            onSort: (method, name) => setSortBy({ method, name }),
        },
        {
            name: 'Tags',
            accessor: 'tags',
            callbackValue: renderTags,
            onSort: (method, name) => setSortBy({ method, name }),
        },
    ];

    const handleSearch = (e) => {
        setSearch(e);
    };

    return (
        <>
            <DataTableWidget
                id="explore-by-equipment"
                isLoading={equipmentsLoading}
                isLoadingComponent={<SkeletonLoader noOfColumns={headerProps.length + 1} noOfRows={20} />}
                onSearch={handleSearch}
                buttonGroupFilterOptions={[]}
                rows={equipments}
                searchResultRows={equipments}
                filterOptions={filterOptions}
                isFilterLoading={loadingFilters}
                headers={headerProps}
                pageSize={pageSize}
                onPageSize={setPageSize}
                currentPage={pageNo}
                onChangePage={setPageNo}
                pageListSizes={pageListSizes}
                totalCount={totalItems}
                isCSVDownloading={isCSVDownloading}
                onDownload={handleDownloadCSV}
            />

            <EquipChartModal
                showEquipmentChart={showEquipmentChart}
                handleChartClose={handleChartClose}
                selectedEquipObj={equipmentFilter}
                fetchEquipmentData={fetchEquipDataList}
                selectedTab={selectedModalTab}
                setSelectedTab={setSelectedModalTab}
                activePage="explore"
            />
        </>
    );
};

export default EquipmentTable;
